<template>
  <div class="">
    <div class="table-responsive text-nowrap">
      <md-table
        :columns="columns"
        :data-source="data"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
         bordered
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template
          #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div class="custom-filter-dropdown">
            <a-input
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm)"
            />
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(selectedKeys, confirm)"
              >Search</a-button
            >
            <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"
              >Reset</a-button
            >
          </div> </template
        ><template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #filterIcon="{ text: filtered }">
          <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
        <template #customer="{ text }">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
          </template>
        </template>
        <template #progress="{ text: bar }">
          <div class="progress">
            <div
              :class="['progress-bar', bar.color]"
              :style="{ width: bar.value + '%' }"
              role="progressbar"
            ></div>
          </div>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #total="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #tax="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #shipping="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #status="{ text }">
          <span
            :class="[
              text
                ? 'font-size-12 badge badge-primary'
                : 'font-size-12 badge badge-default',
            ]"
            >{{ text }}</span
          >
        </template>
        <template #action>
          <span>
            <a-tooltip title="Ubah Kategories">
              <a href="javascript: void(0);" class="btn btn-sm btn-light">
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Hapus Kategories" class="ml-2">
              <a href="javascript: void(0);" class="btn btn-sm btn-light">
                <small>
                  <i class="fe fe-trash" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, watch, inject } from 'vue'
const acolumns = [
  {
    title: 'NO',
    dataIndex: 'no',
     slots: { customRender: 'no' },
    sorter: (a, b) => a.no - b.no,
    align: 'center',
  },
  {
    title: 'UoM',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: 'Satuan Jual',
    dataIndex: 'Satuan_Jual',
    align: 'center',
  },
    {
    title: 'Satuan Beli',
    dataIndex: 'satuan_beli',
    align: 'center',
  },
    {
    title: 'Status',
    dataIndex: 'active',
    sorter: (a, b) => a.status.length - b.status.length,
    slots: { customRender: 'status' },
    align: 'center',
  },

  {
    title: 'Action',
    slots: { customRender: 'action' },
    align: 'center',
  },

]
// function renderVNode(_, { attrs: { vnode } }) {
// return vnode
// }
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
 // components: { renderVNode },
   },
  setup(props) {
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref({})
    const current1 = ref(1)
    console.log('props', { props })
    const perPage = inject('perPage')
    const pageCount = inject('pageCount')
    const totalCount = inject('totalCount')
    const page = inject('page')
    const meta = inject('meta')

    columns.value = acolumns

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }
       const startRow = ref(1)
    // watch(perPage, (newValue, oldValue) => {
    // console.log('The new perPage value is: ' + perPage.value)
    // })
    // {
    //     "totalCount": 62117,
    //     "pageCount": 3106,
    //     "currentPage": 1,
    //     "perPage": 20
    // }
    const handleTableChange = (pag, filters, sorter) => {
      console.log(pag)
      page.value = pag.current
      perPage.value = pag.pageSize
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    return {
      searchText,
      searchInput,
      //data,
      columns,
      handleReset,
      handleSearch,
      current1,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      handleTableChange,
      startRow,
    }
  },
}
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
