<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body">
            <!-- TODO: Komponen Filter Tabel -->
            <m-filter class="mb-4" />
            <m-table :data="data" :per-page="perPage"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MTable from './components/forms/table' // local component
import MFilter from './components/forms/filter' // local component
import apiClient from '@/services/axios'
import { ref, toRefs, watch, inject, onMounted, provide } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'VbSatuan',
  components: {
    MFilter,
    MTable,
  },
  setup() {
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const route = useRoute()
    const data = ref([])
    const meta = ref({})
    const page = ref(1)

    const fetchData = () => {
      // const { page } = route.query
      apiClient
        .get('/api/uoms', { params: { page: page.value, 'per-page': perPage.value } })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta
          pageCount.value = _meta.pageCount
          totalCount.value = _meta.totalCount
        })
    }



    provide('page', page)
    provide('perPage', perPage)
    provide('pageCount', pageCount)
    provide('totalCount', totalCount)
    provide('meta', meta)

    watch(page, fetchData)
    watch(perPage, fetchData)

    onMounted(() => {
      fetchData()
    })

    return {
      perPage,
      data,
      meta,
      pageCount,
      page,
    }
  },
  data() {
    return {
      // data: [],
      // meta: {},
    }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    // this.fetchData()
  },
  methods: {},
}
</script>
