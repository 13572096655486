<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <a-select placeholder="Semua Status" style="width: 300px"></a-select>
      <a-input-search class="ml-2" v-model:value="q" placeholder="Cari ..." style="width: 300px" />
       <a-button class="ml-2" type="primary" title="cari" @click="search">
          <i class="fa fa-search" aria-hidden="true"></i>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Tambah Satuan"
          >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MFilter',
}
</script>

<style scoped>
.row {
  /* border: 1px dotted gray; */
}
</style>
